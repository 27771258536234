import Button from 'components/Button/NewButton';
import ErrorMessage from 'components/ErrorMessage';
import Input from 'components/Input/NewInput';
import useAuth from 'hooks/useAuth';
import { analytics } from 'lib/analytics';
import { useSearchProvider } from 'providers/SearchProvider';
import { useStateItem } from 'providers/stateItem';
import InviteFriendsIcon from 'public/images/icons/inviteFriends.svg';
import { useMemo } from 'react';
import { toast } from 'sonner';
import { sendEmail } from 'utils/sendEmail';
import { emailConstraint } from 'utils/validationConstraints';
import st from './InviteFriends.module.scss';

const InviteFriendsModal: React.FC = () => {
  const [user] = useAuth();
  const SearchProvider = useSearchProvider();
  const inviteAddresses = useStateItem('');
  const error = useStateItem('');
  const isLoading = useStateItem(false);
  const inviteLink = SearchProvider?.share.generateInviteLink();
  const inviterName =
    user?.firstName && user.lastName
      ? `${user.firstName} ${user.lastName}`
      : `${user?.userName}`;

  const emailMessageBody = useMemo(() => {
    if (typeof window === 'undefined') return '';
    return `
    <p>Hey there 👋,</p>
    <p>${inviterName} wants you to sign up to CleeAI and join the millions of professionals using AI-powered search to get to your 'aha' moment faster.</p>
    <ul>
      <li>Become insanely productive ⚡</li>
      <li>Get high-quality responses 😊</li>
      <li>Avoid endless searching 👍</li>
    </ul>
    <p><a href="search.cleeai.com">Claim your free account</a> before it expires and experience the future.</p>
    <p>Remember to thank ${inviterName}!</p>
    <p>CleeAI<br>
    Ask anything. Trust everything.<br>
    AI-Powered Answer Engine.<br>
    <a href="search.cleeai.com">search.cleeai.com</a></p>
  `;
  }, [inviterName]);

  const validateEmail = () => {
    const validation = emailConstraint.safeParse(inviteAddresses.get());

    if (!validation.success) {
      const errors = validation?.error?.flatten();
      error.set(errors?.formErrors[0]);

      return false;
    }
    error.set('');
    return true;
  };

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    Boolean(error.get()) && error.set('');
    inviteAddresses.set(ev.target.value);
  };

  const handleSendInvite = async () => {
    const emailAddress = inviteAddresses.get();
    Boolean(error.get()) && error.set('');
    isLoading.set(true);

    if (emailAddress === user?.userName) {
      error.set('Sorry, you cannot invite yourself.');
      isLoading.set(false);
      return;
    }

    const isValid = validateEmail();
    if (!isValid) {
      isLoading.set(false);
      return;
    }

    try {
      await sendEmail(
        emailAddress,
        `'Skip the Queue' invite from ${inviterName}`,
        emailMessageBody,
      );
      toast.success(
        `Invite email to ${inviteAddresses.get()} successfully sent. Thank you.`,
      );
      inviteAddresses.set('');
      analytics.invitedUser(user, [inviteAddresses.get()]);
    } catch (error) {
      error.set(error);
      analytics.generic(
        user,
        'User email invite from invite friends modal failed',
      );
    } finally {
      isLoading.set(false);
    }
  };

  return (
    <div className={st.container}>
      <div className={st.header}>
        <InviteFriendsIcon alt={''} height={48} />
        <h2 className={st.title}>Invite friends to CleeAI</h2>
      </div>
      <div className={st.referalContainer}>
        <Input
          type={'text'}
          name={'inviteLink'}
          value={inviteLink ?? ''}
          label={'Your referral link'}
          readOnly
        />
        <Button
          variant={'tertiary'}
          isFullWidth
          onClick={() => {
            SearchProvider?.share.shareInvite();
            analytics.sharedInviteLink(user);
          }}
        >
          Copy link
        </Button>
      </div>
      <div className={st.inviteContainer}>
        <Input
          type={'textarea'}
          name={'inviteAddresses'}
          value={inviteAddresses.get()}
          label={'Share via Email'}
          onChange={handleChange}
        />
        <p className={st.helperText}>Separate email by using space or comma.</p>
        <Button
          isFullWidth
          onClick={handleSendInvite}
          disabled={isLoading.get()}
        >
          Send
        </Button>
      </div>
      {Boolean(error) && <ErrorMessage>{error.get()}</ErrorMessage>}
    </div>
  );
};

export default InviteFriendsModal;
