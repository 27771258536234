'use client';

import Button from 'components/Button/NewButton';
import DividerText from 'components/DividerText';
import ErrorMessage from 'components/ErrorMessage';
import Input from 'components/Input/NewInput';
import Ratings from 'components/Ratings';
import useAuth from 'hooks/useAuth';
import { analytics } from 'lib/analytics';
import { trpc } from 'lib/trpc';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useModalProvider } from 'providers/ModalProvider';
import GoogleLogo from 'public/images/icons/google-g-logo-mono.svg';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { fetchWithCredentials } from 'utils/fetchWrapper';
import { genericErrorMap } from 'utils/genericError';
import ServerAPIError from 'utils/ServerAPIError';
import { loginSchema } from 'utils/validationSchemas';
import st from './SignIn.module.scss';
import SignUp from './SignUp';

const defaultFieldErrors = {
  email: [],
  password: [],
};

let already_running = false;

export const SignIn: React.FC = () => {
  const router = useRouter();
  const modal = useModalProvider();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<{
    email?: string[];
    password?: string[];
  }>(defaultFieldErrors);
  const [genericError, setGenericError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [user, , setIsAuthLoading] = useAuth();

  useEffect(() => {
    if (already_running) return; // don't re-run too quickly (e.g. double run on start up)
    already_running = true;
    setTimeout(() => {
      already_running = false;
    }, 100);
    analytics.viewedSigninPage();
  }, []);

  useEffect(() => {
    if (user) modal.clear();
  }, [user, router]);

  const validateOnchange = async (email: string, password: string) =>
    loginSchema
      .safeParseAsync({ email, password })
      .then((validation) =>
        validation.success ? setIsValid(true) : setIsValid(false),
      )
      .catch(() => setIsValid(false));

  async function validate() {
    const validation = loginSchema.safeParse({ email, password });

    if (!validation.success) {
      const errors = validation?.error?.flatten();
      setFieldErrors(errors?.fieldErrors);

      return false;
    }

    return await fetchWithCredentials(
      `${
        process.env.NEXT_PUBLIC_SERVER_BASE_URL
      }/utils/is-valid-domain?email=${encodeURIComponent(email)}`,
    )
      .then(async (response) => {
        const resJson = await response.json();

        if (!response.ok) {
          throw new ServerAPIError(
            `${resJson?.error?.message ?? 'Unexpected error'}`,
            response,
          );
        }

        setFieldErrors(defaultFieldErrors);
        return true;
      })
      .catch((err) => {
        console.error(`[${err?.response?.status}] ${err}`);

        setFieldErrors({
          email: ['Please enter your work email or contact hi@cleeai.com'],
          password: [],
        });

        return false;
      });
  }

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = ev.target;
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  const handleSubmit = async (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    setGenericError('');
    setIsLoading(true);

    const isValid = await validate();
    if (!isValid) {
      setIsLoading(false);
      return;
    }

    try {
      await trpc.auth.signIn.mutate({ email, password });
      setIsAuthLoading(true);
      analytics.signedInEmail(user);
    } catch (err) {
      const genericError =
        genericErrorMap[err.message] ??
        'There has been a problem, please try again';
      setGenericError(genericError);
      analytics.incorrectPassword(email);
      console.error('error logging in:', err);
      setIsLoading(false);
    }
  };

  const handleResendVerificationCodeClick = async (
    ev: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    ev.preventDefault();
    setIsLoading(true);
    try {
      await trpc.auth.resendSignUp.mutate({ email });
      router.push(`/verify?email=${encodeURIComponent(email)}`);
    } catch (err) {
      /** @info: Not sure what to do here, so silently fail for now */
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignup = () => {
    modal.open(<SignUp />);
  };

  useEffect(() => {
    validateOnchange(email, password);
  }, [email, password]);

  return (
    <div className={st.container}>
      <div className={st.textContainer}>
        <h2 className={st.title}>Welcome back!</h2>
        <h3 className={st.subtitle}>Sign in or sign up to continue</h3>
      </div>
      <div className={st.formContainer}>
        <Button
          type={'submit'}
          isFullWidth
          variant={'primary'}
          className={st.googleBtn}
          onClick={async () => {
            const params = new URLSearchParams({
              redirect_uri: process.env.NEXT_PUBLIC_OAUTH_REDIRECT || '',
              response_type: 'code',
              client_id: '',
              identity_provider: 'Google',
              scope: '',
              state: 'state',
              code_challenge: '',
              code_challenge_method: 'S256',
            });
            router.push(
              `http://${process.env.NEXT_PUBLIC_OAUTH_DOMAIN}/oauth2/authorize?${params.toString()}`,
            );
          }}
        >
          <GoogleLogo alt={'Forward arrow icon'} width={20} height={20} />
          Continue with Google
        </Button>
        <DividerText>Sign in with email</DividerText>
        <form onSubmit={handleSubmit} noValidate>
          <fieldset className={st.fieldset}>
            <Input
              type={'email'}
              name={'email'}
              id={'email'}
              label={'Work email'}
              defaultValue={email}
              onChange={handleChange}
              hasError={Boolean(fieldErrors?.email?.length)}
              errorMessage={fieldErrors?.email?.[0] ?? ''}
            />
            <Input
              type={'password'}
              name={'password'}
              id={'password'}
              label={'Password'}
              defaultValue={password}
              onChange={handleChange}
              hasError={Boolean(fieldErrors?.password?.length)}
              errorMessage={fieldErrors?.password?.[0] ?? ''}
            />
            {Boolean(genericError) && (
              <ErrorMessage className={st.genericError}>
                {genericError}
              </ErrorMessage>
            )}
          </fieldset>
          <Button
            type={'submit'}
            isFullWidth
            variant={'tertiary'}
            isLoading={isLoading}
            showDisabled={!isValid}
          >
            Sign in
          </Button>
        </form>
        <Link href={'/forgotten-password'} className={st.forgotPassword}>
          Forgot password
        </Link>
        <Ratings />
        <div className={st.signUpContainer}>
          Don&apos;t have an account?{' '}
          <Button onClick={handleSignup} variant={'secondary'}>
            Sign up
          </Button>
        </div>
      </div>
    </div>
  );
};
