import classNames from 'classnames';
import React, { ChangeEvent, useState } from 'react';
import VisibilityOff from '../../public/images/icons/visibilityOff.svg';
import VisibilityOn from '../../public/images/icons/visibilityOn.svg';

import ErrorMessage from 'components/ErrorMessage';
import st from './NewInput.module.scss';

const uniqueId = (prefix = '') =>
  `${prefix}${Math.random().toString(36).substring(2, 9)}`;

interface Props
  extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  type?: 'text' | 'password' | 'email' | 'tel' | 'url' | 'textarea';
  onChange?: (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  hasError?: boolean;
  errorMessage?: string;
  label?: string;
}

export default function Input({
  type = 'text',
  id = uniqueId(`${type}_`),
  className,
  onChange,
  hasError,
  errorMessage,
  label,
  ...attr
}: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const isTypePassword = type === 'password';
  const inputType = isTypePassword && isVisible ? 'text' : type;
  const [hasContent, setHasContent] = useState(
    !!attr.defaultValue || !!attr.value || false,
  );
  const handleVisibilityChange = () => {
    setIsVisible(!isVisible);
  };
  let autocomplete = '';
  switch (type) {
    case 'password':
      autocomplete = 'current-password';
      break;
    case 'email':
      autocomplete = 'username';
      break;
  }

  const handleChange = (
    ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onChange?.(ev);
    if (ev.target.value.length > 0) {
      setHasContent(true);
    } else {
      setHasContent(false);
    }
  };

  return (
    <div className={st.container}>
      <div className={st.inputContainer}>
        {type === 'textarea' ? (
          <textarea
            {...attr}
            id={id}
            onChange={handleChange}
            data-has-error={hasError}
            className={classNames(st.input, className)}
          />
        ) : (
          <input
            {...attr}
            id={id}
            type={inputType}
            onChange={handleChange}
            data-has-error={hasError}
            data-is-password={isTypePassword}
            className={classNames(st.input, className)}
            autoComplete={autocomplete}
          />
        )}
        {Boolean(label) && (
          <label
            className={st.label}
            htmlFor={id}
            data-is-supertext={hasContent}
          >
            {label}
          </label>
        )}
        {isTypePassword &&
          (isVisible ? (
            <VisibilityOn
              className={st.visibilityIcon}
              alt={'Show password'}
              onClick={handleVisibilityChange}
              height={24}
              width={24}
            />
          ) : (
            <VisibilityOff
              className={st.visibilityIcon}
              alt={'Hide password'}
              onClick={handleVisibilityChange}
              height={24}
              width={24}
            />
          ))}
        {/* {hasError && <Image alt={'Error'} src={errorRed} height={44} />} */}
      </div>
      {Boolean(errorMessage) && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
}
