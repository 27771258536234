'use client';

import Button from 'components/Button/NewButton';
import { DividerText } from 'components/DividerText/DividerText';
import ErrorMessage from 'components/ErrorMessage';
import Input from 'components/Input/NewInput';
import PasswordError from 'components/PasswordError';
import { analytics } from 'lib/analytics';
import { registerAutoLogin } from 'lib/autologin';
import { trpc } from 'lib/trpc';
import { useRouter } from 'next/navigation';
import { useSearchProvider } from 'providers/SearchProvider';
import GoogleLogo from 'public/images/icons/google-g-logo-mono.svg';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { toast } from 'sonner';
import { PRIVACY_POLICY_LINK, TERMS_OF_SERVICE_LINK } from 'utils/constants';
import { fetchWithCredentials } from 'utils/fetchWrapper';
import { genericErrorMap } from 'utils/genericError';
import ServerAPIError from 'utils/ServerAPIError';
import { createAccountSchema } from 'utils/validationSchemas';
import st from './SignUp.module.scss';

const defaultFieldErrors = {
  email: [],
  password: [],
};

interface Props {
  onLoad?: (isLoading: boolean) => void;
  passwordErrorVariant?: 'toast' | 'embedded';
}

export default function SignUp({
  onLoad,
  passwordErrorVariant = 'embedded',
}: Props) {
  const router = useRouter();
  const searchProvider = useSearchProvider();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<{
    email?: string[];
    password?: string[];
  }>(defaultFieldErrors);
  const [genericError, setGenericError] = useState('');

  const validateOnchange = async (email: string, password: string) =>
    createAccountSchema
      .safeParseAsync({ email, password })
      .then((validation) =>
        validation.success ? setIsValid(true) : setIsValid(false),
      )
      .catch(() => setIsValid(false));

  async function validate() {
    const validation = createAccountSchema.safeParse({ email, password });

    if (!validation.success) {
      const errors = validation?.error?.flatten();
      if (
        Boolean(errors?.fieldErrors?.password?.length) &&
        passwordErrorVariant === 'toast'
      ) {
        toast.error(<PasswordError />, {
          duration: 60000,
          position: 'top-center',
        });
      }
      setFieldErrors(errors?.fieldErrors);

      return false;
    }

    try {
      const response = await fetchWithCredentials(
        `${
          process.env.NEXT_PUBLIC_SERVER_BASE_URL
        }/utils/is-valid-domain?email=${encodeURIComponent(email)}`,
      );
      const resJson = await response.json();

      if (!response.ok) {
        throw new ServerAPIError(
          `${resJson?.error?.message ?? 'Unexpected error'}`,
          response,
        );
      }

      setFieldErrors(defaultFieldErrors);
      return true;
    } catch (err) {
      const fieldErrors = {
        email: ['Please enter your work email or contact hi@cleeai.com'],
        password: [],
      };

      setFieldErrors(fieldErrors);

      return false;
    }
  }

  async function handleChange(ev: ChangeEvent<HTMLInputElement>) {
    const { name, value } = ev.target;
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  }

  async function handleSubmit(ev: FormEvent<HTMLFormElement>) {
    ev.preventDefault();
    setGenericError('');
    onLoad?.(true);
    setIsLoading(true);

    const isValid = await validate();
    if (!isValid) {
      onLoad?.(false);
      setIsLoading(false);
      return;
    }

    try {
      await trpc.auth.signUp.mutate({ email, password });
      await registerAutoLogin(email, password);
      analytics.preverified(email);
      router.push(`/verify?email=${encodeURIComponent(email)}`);
      searchProvider?.anonymousSearch.setOpenModal(null);
    } catch (err) {
      const genericError =
        genericErrorMap[err.message] ??
        'There has been a problem, please try again';
      setGenericError(genericError);
      console.error('error signing up:', err);
    } finally {
      onLoad?.(false);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    validateOnchange(email, password);
  }, [email, password]);

  return (
    <div className={st.container}>
      <div className={st.textContainer}>
        <h2 className={st.title}>Access to unlock more features</h2>
        <h3 className={st.subtitle}>Sign in or sign up to continue</h3>
      </div>
      <div className={st.formContainer}>
        <Button
          type={'submit'}
          isFullWidth
          variant={'primary'}
          className={st.googleBtn}
          onClick={async () => {
            const params = new URLSearchParams({
              redirect_uri: process.env.NEXT_PUBLIC_OAUTH_REDIRECT || '',
              response_type: 'code',
              client_id: '',
              identity_provider: 'Google',
              scope: '',
              state: 'state',
              code_challenge: '',
              code_challenge_method: 'S256',
            });
            router.push(
              `http://${process.env.NEXT_PUBLIC_OAUTH_DOMAIN}/oauth2/authorize?${params.toString()}`,
            );
          }}
        >
          <GoogleLogo alt={'Forward arrow icon'} width={20} height={20} />
          Continue with Google
        </Button>
        <DividerText>Sign up with email</DividerText>
        <form onSubmit={handleSubmit} noValidate>
          <fieldset className={st.fieldset}>
            <Input
              type={'email'}
              name={'email'}
              id={'email'}
              label={'Work email'}
              defaultValue={email}
              onChange={handleChange}
              hasError={Boolean(fieldErrors?.email?.length)}
              errorMessage={fieldErrors?.email?.[0] ?? ''}
            />
            <Input
              type={'password'}
              name={'password'}
              id={'password'}
              label={'Password'}
              defaultValue={password}
              onChange={handleChange}
              hasError={Boolean(fieldErrors?.password?.length)}
              errorMessage={fieldErrors?.password?.[0] ?? ''}
            />
            {Boolean(genericError) && (
              <ErrorMessage className={st.genericError}>
                {genericError}
              </ErrorMessage>
            )}
          </fieldset>
          <Button
            type={'submit'}
            isFullWidth
            variant={'tertiary'}
            isLoading={isLoading}
            showDisabled={!isValid}
          >
            Sign up
          </Button>
          <p className={st.tos}>
            By clicking sign up, you agree our{' '}
            <a href={TERMS_OF_SERVICE_LINK} target={'_blank'}>
              Terms of Service
            </a>{' '}
            and that you have read our{' '}
            <a href={PRIVACY_POLICY_LINK} target={'_blank'}>
              Privacy Policy
            </a>
            .
          </p>
        </form>
        {Boolean(fieldErrors?.password?.length) &&
        passwordErrorVariant === 'embedded' ? (
          <PasswordError />
        ) : null}
      </div>
    </div>
  );
}
