export default class ServerAPIError extends Error {
  response: Response;

  constructor(message: string, response: Response) {
    super(message);

    /** @info: Set the prototype explicitly for TS */
    Object.setPrototypeOf(this, ServerAPIError.prototype);

    this.name = 'ServerAPIError';
    this.response = response;
  }
}
