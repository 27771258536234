import Modal from 'components/Modal/NewModal';
import SignUp from 'components/ModalContent/SignUp';

import type { ModalProps } from 'components/Modal/NewModal';
import Spinner from 'components/Spinner';
import { useState } from 'react';

interface Props {
  isOpen?: boolean;
  onCloseCB: ModalProps['onClose'];
}

export default function ModalCreateAccount({
  isOpen = false,
  onCloseCB,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal isOpen={isOpen} onClose={onCloseCB}>
      {isLoading && <Spinner fullScreen={true} />}
      <SignUp
        onLoad={(bool) => setIsLoading(bool)}
        passwordErrorVariant={'embedded'}
      />
    </Modal>
  );
}
