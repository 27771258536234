import type React from 'react';
import st from './DividerText.module.scss';

export const DividerText: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <div className={st.divider}>
    <span className={st.dividerText}>{children}</span>
  </div>
);
export default DividerText;
