import Button from 'components/Button/NewButton';
import SearchModal from 'components/SearchModal';
import useAuth from 'hooks/useAuth';
import { analytics } from 'lib/analytics';
import { useSearchProvider } from 'providers/SearchProvider';
import AddIcon from 'public/images/icons/add.svg';
import { useState } from 'react';

interface NewSearchBtnProps {
  className?: string;
  btnText?: string;
}

export default function NewSearchBtn({
  className = '',
  btnText = 'New Chat',
}: NewSearchBtnProps) {
  const [user] = useAuth();
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const searchProvider = useSearchProvider();
  const isStreaming = searchProvider?.search.getIsStreaming();

  return (
    <>
      <Button
        variant={'primary'}
        onClick={() => {
          setIsSearchModalOpen(true);
          analytics.generic(user, 'New Search clicked');
        }}
        align={'left'}
        isFullWidth
        className={className}
        disabled={isStreaming}
      >
        <AddIcon alt={''} height={24} />
        {btnText}
      </Button>
      <SearchModal
        isModalOpen={isSearchModalOpen}
        onCloseCB={() => setIsSearchModalOpen(false)}
      />
    </>
  );
}
