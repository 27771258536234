import { z } from 'zod';
import {
  emailConstraint,
  loginPasswordConstraint,
  passwordConstraint,
  verificationCodeConstraint,
} from './validationConstraints';

export const createAccountSchema = z.object({
  email: emailConstraint,
  password: passwordConstraint,
});

export const verifyEmailSchema = z.object({
  verificationCode: verificationCodeConstraint,
});

export const loginSchema = z.object({
  email: emailConstraint,
  password: loginPasswordConstraint,
});

export const resetPasswordSchema = z.object({
  verificationCode: verificationCodeConstraint,
  newPassword: passwordConstraint,
});
