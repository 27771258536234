import { ReactNode } from 'react';
import classNames from 'classnames';
import style from './style.module.css';

interface Props {
  children: string | ReactNode;
  className?: string;
}

export default function ErrorMessage({ children, className = '' }: Props) {
  return (
    <div
      className={classNames(style.error, { [className]: Boolean(className) })}
    >
      {children}
    </div>
  );
}
