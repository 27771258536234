'use client';

import type { UserWithSubscriptionDetails } from '@server/types';
import Button from 'components/Button/NewButton';
import AccountSettingsModal from 'components/ModalContent/AccountSettings';
import InviteFriendsModal from 'components/ModalContent/InviteFriends';
import NewSearchBtn from 'components/NewSearchBtn';
import QuestionTooltip from 'components/QuestionTooltip';
import TagContainer from 'components/TagContainer';
import useAuth from 'hooks/useAuth';
import { analytics } from 'lib/analytics';
import { trpc } from 'lib/trpc';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useModalProvider } from 'providers/ModalProvider';
import { useSearchProvider } from 'providers/SearchProvider';
import cleeaiLogoV2 from 'public/images/icons/cleeaiLogoV2.svg?url';
import CloseIcon from 'public/images/icons/close.svg';
import InviteFriendsIcon from 'public/images/icons/inviteFriends.svg';
import LightbulbBlueIvy from 'public/images/icons/lightbulbBlueIvy.svg';
import PersonOutlineIcon from 'public/images/icons/personOutline.svg';
import ViewAgenda from 'public/images/icons/view_agenda.svg';
import { MouseEvent, useCallback, useRef, useState } from 'react';
import EmptyState from './emptyState';
import st from './style.module.css';

interface SidebarProps {
  closeNavCB: (e: MouseEvent) => void;
}

export function Sidebar({ closeNavCB }: SidebarProps) {
  const [user] = useAuth();
  const modal = useModalProvider();
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(false);

  const searchProvider = useSearchProvider();
  const taggedSearches = searchProvider?.savedSearches.get() || [];
  const expandedTags = searchProvider?.savedSearches.getOpenedTags(false) || [];
  const pathName = usePathname();
  const isHome = pathName == '/';
  const discoveryBtnRef = useRef<HTMLButtonElement>(null);
  const discoverBtnTxt = searchProvider?.discovery.getButtonText();

  const showUserProfileModal = useCallback(() => {
    modal.open(<AccountSettingsModal />);
    analytics.generic(user, 'My account button clicked');
  }, [modal]);

  const { id, authUserId, subscriptionDetails } =
    user as UserWithSubscriptionDetails;

  const upgradeToPro = async () => {
    setIsSubscriptionLoading(true);
    analytics.generic(user, 'Clicked upgrade to Pro, in sidebar');
    await trpc.user.createSubscriptionCheckout
      .query({
        id,
        authUserId,
      })
      .then((checkoutSession) => {
        if (typeof window !== 'undefined' && checkoutSession?.url) {
          window.location.href = checkoutSession.url;
        }
      })
      .catch((error) => {
        console.error(
          '%c ERROR CREATING SUBSCRIPTION CHECKOUT: ',
          'background:red;color:white;',
          error,
        );
      })
      .finally(() => {
        setIsSubscriptionLoading(false);
      });
  };

  return (
    <div className={st.container}>
      <CloseIcon
        className={st.closeIcon}
        alt={'Close Icon'}
        onClick={closeNavCB}
        height={24}
        width={24}
      />
      <Link href={'/'}>
        <Image src={cleeaiLogoV2} className={st.logoSidebar} alt={'Logo'} />
      </Link>
      {!isHome && <NewSearchBtn className={st.sidebarButton} />}
      <div className={st.discoverContainer}>
        <Button
          variant={'highlight'}
          align={'left'}
          isFullWidth
          onClick={() => {
            searchProvider?.discovery.perform();
            analytics.generic(user, 'Discover button clicked');
          }}
          className={st.sidebarButton}
          disabled={searchProvider?.discovery.isDisabled()}
          ref={discoveryBtnRef}
        >
          <LightbulbBlueIvy alt={''} height={24} />
          {discoverBtnTxt}
        </Button>
        {discoveryBtnRef.current && (
          <div className={st.discoverTooltip}>
            <QuestionTooltip
              text={
                searchProvider?.discovery.isDisabled()
                  ? 'Hang tight! I will be ready in ~30 sec'
                  : searchProvider?.discovery.getTooltip() || ''
              }
              leftOffset={discoveryBtnRef.current?.offsetWidth}
            />
          </div>
        )}
      </div>
      <div className={st.searchesHeader}>
        <ViewAgenda alt={''} height={24} />
        <p className={st.subHeading}>Searches</p>
      </div>
      {taggedSearches.length ? (
        <div className={st.searchItemsList}>
          <ul className={st.tagSearches}>
            {taggedSearches.map((tagSearch) => {
              const { tag, searches } = tagSearch;
              return (
                <li key={tag}>
                  <TagContainer
                    searches={searches}
                    tag={tagSearch.tag}
                    closeNavCB={closeNavCB}
                    isExpanded={expandedTags.includes(tag)}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <EmptyState />
      )}
      {subscriptionDetails === null && (
        <Button
          variant={'highlight'}
          align={'left'}
          isFullWidth
          isExternal
          isLoading={isSubscriptionLoading}
          onClick={upgradeToPro}
          className={st.sidebarButton}
        >
          Upgrade to Pro
        </Button>
      )}
      <Button
        variant={'text'}
        align={'left'}
        isFullWidth
        onClick={() => {
          modal.open(<InviteFriendsModal />);
          analytics.generic(user, 'Invite Friends button clicked');
        }}
        className={st.sidebarButton}
      >
        <InviteFriendsIcon alt={''} height={24} />
        Invite friends
      </Button>
      <Button
        variant={'text'}
        align={'left'}
        isFullWidth
        onClick={showUserProfileModal}
        className={st.sidebarButton}
      >
        <PersonOutlineIcon alt={''} height={24} />
        My account
      </Button>
    </div>
  );
}
