import classNames from 'classnames';
import style from './Ratings.module.css';
import Image from 'next/image';
import fiveStartsRating from 'public/images/icons/fiveStars.svg?url';
import taaftLogo from 'public/images/icons/taaftLogo.svg?url';
import trustpilotStar from 'public/images/icons/trustpilotStar.svg?url';
import TrustpilotRating from 'public/images/icons/trustpilotRating.svg';
import npsScore from 'public/images/icons/npsScore.svg?url';
import Link from 'next/link';
import { Roboto } from 'next/font/google';

const roboto = Roboto({
  weight: ['400'],
  style: ['normal'],
  subsets: ['latin'],
});

export default function Ratings() {
  return (
    <div className={style.container}>
      <p className={style.paragraph}>Trusted reviews</p>
      <div className={classNames(style.ratingsContainer, style.flex)}>
        <Link
          className={classNames(style.taaftRating, style.flex)}
          href="https://theresanaiforthat.com/ai/cleeai/"
          target="_blank"
        >
          <div className={classNames(style.taaftLogo, style.flex)}>
            <Image
              src={taaftLogo}
              alt="There's an AI for that logo"
              width={18}
            />
          </div>
          <Image src={fiveStartsRating} alt="Stars rating" />
        </Link>
        <Link
          className={classNames(
            style.trustpilotRating,
            style.flex,
            roboto.className,
          )}
          href="https://www.trustpilot.com/review/cleeai.com"
          target="_blank"
        >
          <div className={classNames(style.top, style.flex)}>
            <Image src={trustpilotStar} alt="Trustpilot logo star" />
            <p className={style.header}>Trustpilot</p>
          </div>
          <TrustpilotRating />
        </Link>
        <Image src={npsScore} alt="NPS score" className={style.npsScore} />
      </div>
    </div>
  );
}
