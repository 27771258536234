import Navbar from 'components/Navbar/unauth';
import { FC, ReactNode } from 'react';
import style from './style.module.css';

interface UnAuthLayoutProps {
  Footer?: FC;
  isLogoVisible?: boolean;
  children: ReactNode;
}

export function UnauthLayout({
  isLogoVisible = true,
  children,
  Footer,
}: UnAuthLayoutProps) {
  return (
    <div className={style.unauthLayout}>
      <Navbar isLogoVisible={isLogoVisible} />
      <div className={style.content}>{children}</div>
      {Footer && (
        <div className={style.footer}>
          <Footer />
        </div>
      )}
    </div>
  );
}
