'use client';

import style from './style.module.css';
import { analytics } from 'lib/analytics';

export function UnauthFooter() {
  return (
    <div className={style.unauthFooter}>
      <a
        href="https://ai.cleeai.com/faq?utm_source=cleeai_site&utm_medium=footer"
        onClick={() => {
          analytics.clickedFooterLinkUnauth(
            'https://ai.cleeai.com/faq?utm_source=cleeai_site&utm_medium=footer',
            'FAQ',
          );
        }}
      >
        FAQ
      </a>
      <a
        href="https://ai.cleeai.com/?utm_source=cleeai_site&utm_medium=footer"
        onClick={() => {
          analytics.clickedFooterLinkUnauth(
            'https://ai.cleeai.com/?utm_source=cleeai_site&utm_medium=footer',
            'About',
          );
        }}
      >
        About
      </a>
      <a
        href="https://www.linkedin.com/company/cleeai"
        onClick={() => {
          analytics.clickedFooterLinkUnauth(
            'https://www.linkedin.com/company/cleeai',
            'Linkedin',
          );
        }}
      >
        Linkedin
      </a>
    </div>
  );
}
