'use client';

import style from './style.module.css';
import { MouseEvent } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import hamburgerMenu from 'public/images/icons/hamburgerMenu.svg?url';
import logoV2 from 'public/images/icons/cleeaiLogoV2.svg?url';
import NewSearchBtn from 'components/NewSearchBtn';

interface NavbarMobileProps {
  openNavCB: (e: MouseEvent) => void;
}

export function NavbarMobile({ openNavCB }: NavbarMobileProps) {
  return (
    <nav className={style.navbarMobileAuth}>
      <div className={style.container}>
        <div className={style.leftAreaMobileAuth}>
          <Image src={hamburgerMenu} onClick={openNavCB} alt="Hamburger Menu" />
          <Link href="/">
            <Image
              src={logoV2}
              alt="Logo"
              height={44}
              width={110}
              className={style.mobileLogo}
            />
          </Link>
        </div>
        <div className={style.rightArea}>
          <div className={style.navMWithLogo}>
            <NewSearchBtn btnText="New" />
          </div>
        </div>
      </div>
    </nav>
  );
}
