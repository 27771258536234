import classNames from 'classnames';
import Button from 'components/Button/NewButton';
import ErrorMessage from 'components/ErrorMessage';
import Input from 'components/Input/NewInput';
import useAuth from 'hooks/useAuth';
import { analytics } from 'lib/analytics';
import { trpc } from 'lib/trpc';
import { ServerTypes } from 'lib/types';
import { useModalProvider } from 'providers/ModalProvider';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { toast } from 'sonner';
import { z } from 'zod';
import st from './AccountSettings.module.scss';

interface UserDetails {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  companyName: string;
  role: string;
  team: string;
}

const defaultFieldErrors = {
  firstName: [],
  lastName: [],
  phoneNumber: [],
  companyName: [],
  role: [],
  team: [],
};

const requiredErrorMessage = 'is required';

const UserDetailsSchema = z.object({
  firstName: z.string().min(1, `First name ${requiredErrorMessage}`),
  lastName: z.string().min(1, `Last name ${requiredErrorMessage}`),
  phoneNumber: z.string().optional(),
  companyName: z.string().optional(),
  team: z.string().optional(),
  role: z.string().optional(),
});

export default function AccountSettingsModal() {
  const [user, setUser] = useAuth();
  const modal = useModalProvider();
  const [isSaving, setIsSaving] = useState(false);
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(false);

  if (!user) {
    modal.clear();
  }

  const {
    id,
    authUserId,
    userName,
    firstName,
    lastName,
    phoneNumber,
    companyName,
    role,
    team,
    subscriptionDetails,
  } = user as ServerTypes.UserWithSubscriptionDetails;

  const [formFields, setFormFields] = useState<UserDetails>({
    firstName: firstName || '',
    lastName: lastName || '',
    phoneNumber: phoneNumber || '',
    companyName: companyName || '',
    role: role || '',
    team: team || '',
  });

  const [fieldErrors, setFieldErrors] = useState<{
    firstName?: string[];
    lastName?: string[];
    phoneNumber?: string[];
    companyName?: string[];
    role?: string[];
    team?: string[];
  }>(defaultFieldErrors);

  const [emailError, setEmailError] = useState('');
  const [genericError, setGenericError] = useState('');

  const hasSubscription = subscriptionDetails !== null;

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = ev.target;
    setFormFields((previous) => ({ ...previous, [name]: value }));
    setFieldErrors(defaultFieldErrors);
  };

  const handleSubmit = async (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    Boolean(genericError) && setGenericError('');
    Boolean(emailError) && setEmailError('');
    const validation = UserDetailsSchema.safeParse(formFields);
    if (!validation.success) {
      const validationErrors = validation?.error?.flatten();
      setFieldErrors(validationErrors?.fieldErrors);
      return;
    } else {
      setIsSaving(true);
      await trpc.user.update
        .mutate({
          id,
          authUserId,
          ...formFields,
        })
        .then((updatedUser) => {
          setUser(updatedUser as ServerTypes.UserWithSubscriptionDetails);
          toast.success('User details successfully updated!');
          modal.setIsOpen(false);
        })
        .catch((error) => {
          setGenericError(
            'User details update failed. Please try again later or contact support.',
          );
          console.error(error);
        })
        .finally(() => {
          setIsSaving(false);
          setFieldErrors(defaultFieldErrors);
        });
    }
  };
  const upgradeToPro = async () => {
    setIsSubscriptionLoading(true);
    analytics.generic(user, 'Clicked upgrade to Pro, in account modal');
    await trpc.user.createSubscriptionCheckout
      .query({
        id,
        authUserId,
      })
      .then((checkoutSession) => {
        if (typeof window !== 'undefined' && checkoutSession?.url) {
          window.location.href = checkoutSession.url;
        }
      })
      .catch((error) => {
        console.error(
          '%c ERROR CREATING SUBSCRIPTION CHECKOUT: ',
          'background:red;color:white;',
          error,
        );
      })
      .finally(() => {
        setIsSubscriptionLoading(false);
      });
  };

  const manageSubscription = async () => {
    setIsSubscriptionLoading(true);
    analytics.generic(user, 'Clicked manage subscription, in account modal');
    await trpc.user.manageSubscription
      .query({
        id,
        authUserId,
      })
      .then((customerPortalSession) => {
        if (typeof window !== 'undefined' && customerPortalSession?.url) {
          window.location.href = customerPortalSession.url;
        }
      })
      .catch((error) => {
        console.error(
          '%c PAYMENT UPDATE ERROR: ',
          'background:red;color:white;',
          error,
        );
      })
      .finally(() => {
        setIsSubscriptionLoading(false);
      });
  };

  const handleLogout = () => {
    modal.clear();
    analytics.signedOut(user);
  };

  useEffect(() => {
    analytics.generic(user, 'Viewed upgrade modal');
  }, []);

  return (
    <div className={st.container}>
      <form className={st.form} onSubmit={handleSubmit}>
        <fieldset className={st.userDetails}>
          <div
            className={classNames(st.inputContainer, st.emailAddressContainer)}
            onClick={() =>
              setEmailError(
                'Email address cannot be changed. This field is not editable.',
              )
            }
            aria-readonly
          >
            <Input
              label={'Email'}
              value={userName}
              onChange={() => undefined}
              disabled
              readOnly
              onBlur={() => Boolean(emailError) && setEmailError('')}
              className={st.emailInput}
              errorMessage={emailError}
            ></Input>
          </div>
          <div className={st.inputContainer}>
            <Input
              type={'text'}
              name={'firstName'}
              label={'First Name'}
              id={'firstName'}
              value={formFields.firstName}
              onChange={handleChange}
              hasError={Boolean(fieldErrors?.firstName?.length)}
              errorMessage={fieldErrors?.firstName?.[0] ?? ''}
            />
          </div>
          <div className={st.inputContainer}>
            <Input
              type={'text'}
              name={'lastName'}
              label={'Last Name'}
              id={'lastName'}
              value={formFields.lastName}
              onChange={handleChange}
              hasError={Boolean(fieldErrors?.lastName?.length)}
              errorMessage={fieldErrors?.lastName?.[0] ?? ''}
            />
          </div>
          <div className={st.inputContainer}>
            <Input
              type={'tel'}
              name={'phoneNumber'}
              label={'Phone Number'}
              id={'phoneNumber'}
              value={formFields.phoneNumber}
              onChange={handleChange}
              hasError={Boolean(fieldErrors?.phoneNumber?.length)}
              errorMessage={fieldErrors?.phoneNumber?.[0]}
            />
          </div>
          <div className={st.inputContainer}>
            <Input
              type={'text'}
              name={'companyName'}
              label={'Company Name'}
              id={'companyName'}
              value={formFields.companyName}
              onChange={handleChange}
              hasError={Boolean(fieldErrors?.companyName?.length)}
              errorMessage={fieldErrors?.companyName?.[0] ?? ''}
            />
          </div>
          <div className={st.inputContainer}>
            <Input
              type={'text'}
              name={'role'}
              label={'Role'}
              id={'role'}
              value={formFields.role}
              onChange={handleChange}
              hasError={Boolean(fieldErrors?.role?.length)}
              errorMessage={fieldErrors?.role?.[0] ?? ''}
            />
          </div>
          <div className={st.inputContainer}>
            <Input
              type={'text'}
              name={'team'}
              label={'Team'}
              id={'team'}
              value={formFields.team}
              onChange={handleChange}
              hasError={Boolean(fieldErrors?.team?.length)}
              errorMessage={fieldErrors?.team?.[0] ?? ''}
            />
          </div>
        </fieldset>
        <div className={st.actions}>
          <fieldset className={st.manageSubscription}>
            {hasSubscription ? (
              <Button
                className={st.manageSubscriptionBtn}
                variant={'secondary'}
                onClick={manageSubscription}
                isLoading={isSubscriptionLoading}
                isExternal
              >
                Manage Subscription
              </Button>
            ) : (
              <Button
                className={st.upgradeBtn}
                variant={'highlight'}
                onClick={upgradeToPro}
                isLoading={isSubscriptionLoading}
                isExternal
              >
                Upgrade to Pro
              </Button>
            )}
          </fieldset>
          <fieldset className={st.accountSettingsBtns}>
            <Button
              className={st.logoutBtn}
              variant={'tertiary'}
              href={'/logout'}
              onClick={handleLogout}
            >
              Logout
            </Button>
            <Button
              className={st.submitButton}
              type={'submit'}
              variant={'primary'}
              isLoading={isSaving}
            >
              Update Profile
            </Button>
          </fieldset>
        </div>
        {Boolean(genericError) && <ErrorMessage>{genericError}</ErrorMessage>}
      </form>
    </div>
  );
}
