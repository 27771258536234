import { z } from 'zod';
import {
  numeric,
  uppercase,
  lowercase,
  symbol,
  noLeadingOrTrailingWhiteSpace,
  phoneNumber,
  email,
} from './regex';

export const emailConstraint = z
  .string()
  .min(1, 'Email is required')
  .email('Email must be valid');
// .regex(email, 'Email must be a valid format')

export const loginPasswordConstraint = z
  .string()
  .min(1, 'Password is required')
  .min(8, 'Password must have a minimum of 8 characters');

export const passwordConstraint = loginPasswordConstraint
  .regex(numeric, 'Password must have a minimum of 1 numeric character')
  .regex(uppercase, 'Password must have a minimum of 1 uppercase letter')
  .regex(lowercase, 'Password must have a minimum of 1 lowercase letter')
  .regex(
    noLeadingOrTrailingWhiteSpace,
    'Password cannot start or end with a white space character',
  )
  .regex(
    symbol,
    'Password must have a minimum of 1 special character or symbol',
  );

export const verificationCodeConstraint = z
  .string()
  .min(1, 'Verification code is required')
  .regex(numeric, 'Verfication code must have numeric characters only')
  .length(6, 'Verification code must be 6 numeric characters');

export function createPhoneNumberConstraints(
  customMessages: {
    minRequiredError?: string;
    minLengthError?: string;
    maxLengthError?: string;
    formatError?: string;
  } = {},
) {
  return z
    .string()
    .min(1, customMessages.minRequiredError || 'Phone number is required')
    .min(6, customMessages.minLengthError || 'Phone number is too short.')
    .max(15, customMessages.maxLengthError || 'Phone number is too long.')
    .regex(
      phoneNumber,
      customMessages.formatError || 'Invalid phone number format.',
    );
}
