import { ErrorMap } from 'lib/types';

export const genericErrorMap: ErrorMap = {
  'Username cannot be empty':
    'There has been a problem. Please go back to the previous page and try again.',
  'User already exists': 'That email already exists',
  'User is not confirmed.':
    'User has not been confirmed',
  'User does not exist.': 'User does not exist',
  'Username/client id combination not found.':
    'User with this email address not found.',
  'Incorrect username or password.':
    'Incorrect email address or password',
  'Invalid verification code provided, please try again.':
    'Invalid verification code, try again or click the link below to get another code',
  'Invalid code provided, please request a code again.':
    'Invalid code provided. Please request a code again.',
  'User cannot be confirmed. Current status is CONFIRMED':
    'Account has already been confirmed',
  'Attempt limit exceeded, please try after some time.':
    'Attempt limit exceeded, please try again later',
};
