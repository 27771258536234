import { ChangeEvent, FormEvent, forwardRef, useEffect, useRef } from 'react';
import style from './style.module.css';
import Chevron from 'public/images/icons/chevron-rounded.svg';
import Button from 'components/Button/NewButton';

interface Props {
  placeholderText?: string;
  readOnly?: boolean;
  isDisabled?: boolean;
  onSubmitCB: () => void;
  onChangeCB: (ev: ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
}

export default forwardRef<HTMLTextAreaElement, Props>(function SearchBox(
  {
    readOnly = false,
    placeholderText = 'Ask anything...',
    isDisabled = false,
    value,
    onSubmitCB,
    onChangeCB,
  },
  ref,
) {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmitCB();
  };

  const handlePressEnter = (ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (ev.key === 'Enter' && !ev.shiftKey) {
      ev.preventDefault();
      onSubmitCB();
    }
  };

  return (
    <div className={style.formContainer}>
      <div className={style.inputBox}>
        <form onSubmit={handleSubmit} className={style.form}>
          <div className={style.formRow}>
            <textarea
              name="queryText"
              placeholder={placeholderText}
              className={style.input}
              readOnly={readOnly}
              onChange={onChangeCB}
              onKeyDown={handlePressEnter}
              value={value}
              disabled={isDisabled}
              ref={ref || inputRef}
            />
            <Button
              type="submit"
              variant="tertiary"
              className={style.submitBtn}
              disabled={isDisabled}
              shape={'circle'}
            >
              <Chevron
                alt="Submit"
                height={10}
                width={10}
                className={style.icon}
              />
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
});
