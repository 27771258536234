import SearchBox from 'components/SearchBox';
import { useSearchProvider } from 'providers/SearchProvider';

interface Props {
  className: string;
}

export default function UnauthSearchBox({ className }: Props) {
  const searchProvider = useSearchProvider();
  const anonymousQA = searchProvider?.anonymousSearch.getAnonymousQA()[0];

  async function handleInputOnSubmit() {
    searchProvider?.anonymousSearchForm.onSubmit();
  }

  return (
    <>
      <div className={className}>
        <SearchBox
          readOnly={false}
          isDisabled={
            searchProvider?.anonymousSearchForm.isDisabled() ||
            anonymousQA?.getIsStreaming(false)
          }
          onSubmitCB={handleInputOnSubmit}
          onChangeCB={(ev) =>
            searchProvider?.anonymousSearchForm.onValueChange(ev)
          }
          value={
            searchProvider?.anonymousSearchForm.getSearchInputValue(false) || ''
          }
        />
      </div>
    </>
  );
}
