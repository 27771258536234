import { trpc } from './trpc';

let autologin: (() => Promise<void>) | null = null;

export async function handleAutoLogin(): Promise<boolean> {
  const saved = autologin;
  autologin = null;
  if (saved) {
    await saved();
    return true;
  }
  return false;
}

export async function registerAutoLogin(email: string, password: string) {
  autologin = async () => {
    await trpc.auth.signIn.mutate({ email, password });
  };
}
