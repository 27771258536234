import { z } from 'zod';
import { fetchWithCredentials } from 'utils/fetchWrapper';

const simpleEmailSchema = z.string().email();

export const sendEmail = async (
  recipient: string,
  subject: string,
  message: string
) => {
  simpleEmailSchema.parse(recipient);

  const reqBody = { recipient, subject, message };
  const res = await fetchWithCredentials(
    `${process.env.NEXT_PUBLIC_SERVER_BASE_URL}/ses`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reqBody),
    }
  );
  const resJson = await res.json();
  resJson.data.recipient = recipient;
  return resJson;
};
