import classNames from 'classnames';
import Button from 'components/Button/NewButton';
import useAuth from 'hooks/useAuth';
import { analytics } from 'lib/analytics';
import { trpc } from 'lib/trpc';
import { ServerTypes } from 'lib/types';
import { useModalProvider } from 'providers/ModalProvider';
import { MAX_FREE_QUESTIONS_PER_DAY } from 'providers/qnaAPI';
import { useState } from 'react';
import CleeaiProLogo from '../../public/images/cleeai-pro-logo.svg';
import st from './ProUpgrade.module.scss';

export const ProUpgradeModal = () => {
  const [user] = useAuth();
  const modal = useModalProvider();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { id, authUserId, subscriptionDetails } =
    user as ServerTypes.UserWithSubscriptionDetails;

  const hasSubscription = subscriptionDetails !== null;

  const upgradeToPro = async () => {
    setIsLoading(true);
    analytics.generic(user, 'Clicked upgrade to Pro, in upgrade modal');
    await trpc.user.createSubscriptionCheckout
      .query({
        id,
        authUserId,
      })
      .then((checkoutSession) => {
        if (typeof window !== 'undefined' && checkoutSession?.url) {
          window.location.href = checkoutSession.url;
        }
      })
      .catch((error) => {
        console.error(
          '%c ERROR CREATING SUBSCRIPTION CHECKOUT: ',
          'background:red;color:white;',
          error,
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const manageSubscription = async () => {
    setIsLoading(true);
    analytics.generic(user, 'Clicked manage subscription, in upgrade modal');
    await trpc.user.manageSubscription
      .query({
        id,
        authUserId,
      })
      .then((customerPortalSession) => {
        if (typeof window !== 'undefined' && customerPortalSession?.url) {
          window.location.href = customerPortalSession.url;
        }
      })
      .catch((error) => {
        console.error(
          '%c USER PORTAL ERROR: ',
          'background:red;color:white;',
          error,
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const continueWithFree = () => {
    console.log('Continue with free');
    modal.setIsOpen(false);
  };

  return (
    <div className={st.container}>
      <div className={st.header}>
        <CleeaiProLogo width={110} />
        <h2 className={st.title}>Upgrade your plan</h2>
      </div>
      <div className={st.plans}>
        <div className={st.plan}>
          <div className={st.planSection}>
            <h3 className={st.planTitle}>Free</h3>
            <p>For people just getting started with CleeAI</p>
          </div>
          <div className={st.planSection}>
            <h4 className={st.price}>$0</h4>
            <p>Monthly</p>
          </div>
          <ul className={classNames(st.benefitsList, st.free)}>
            <li>{MAX_FREE_QUESTIONS_PER_DAY} questions per day</li>
            <li>Standard AI modal</li>
          </ul>
          <Button variant={'secondary'} isFullWidth onClick={continueWithFree}>
            Continue with free
          </Button>
        </div>
        <div className={st.plan}>
          <div className={st.planSection}>
            <h3 className={st.planTitle}>Professional</h3>
            <p>Unlock unlimited queries and a faster model</p>
          </div>
          <div className={st.planSection}>
            <h4 className={st.price}>$15</h4>
            <p>Monthly</p>
          </div>
          <ul className={classNames(st.benefitsList, st.pro)}>
            <li>Unlimited questions</li>
            <li>Access to GPT 4o</li>
            <li>Priority access to API</li>
            <li>Early Access to features</li>
            <li>Priority access during high traffic periods</li>
          </ul>
          <Button
            variant={'primary'}
            isFullWidth
            onClick={hasSubscription ? manageSubscription : upgradeToPro}
            isLoading={isLoading}
            isExternal
          >
            {hasSubscription ? 'Manage subscription' : 'Upgrade to Pro'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProUpgradeModal;
