import style from './style.module.css';

const passwordSpecialCharacterMessage = `^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ \u0060 + ='`;

export default function PasswordError() {
  return (
    <div className={style.container}>
      <p className={style.text}>
        Your password is not strong enough. New passwords must:
      </p>
      <ul>
        <li>Be at least eight characters long</li>
        <li>Contain one or more numbers</li>
        <li>Contain one uppercase character</li>
        <li>Contain one lowercase character</li>
        <li>
          Contain at least one of the following special characters:
          <br />
          {passwordSpecialCharacterMessage} or a non-leading / non-trailing
          space
        </li>
      </ul>
    </div>
  );
}
