export { restrictedDomains } from '@server/utils/regex';

export const numeric = /\d/;
export const uppercase = /[A-Z]/;
export const lowercase = /[a-z]/;
export const noLeadingOrTrailingWhiteSpace = /^(?!\s)(?!.*\s$).*/;
export const symbol = /[ $*.{}()?!"@#%&\/\\,><':;|_~`+='-/\[\]^]+/;
export const byQuestionMarkOrNewLine = /\?[\n]*|\n/g;
export const specialCharacters = /^[`'";:,.\s]+|[`'";:,\s]+$/g;
export const phoneNumber = /^(\+|00)?[0-9]+(\s*[0-9]*)*$/; //temporary phone number regex, accepts empty spaces between digits
export const email =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/; //TODO: check if regex is good enough for validating emails
