'use client';
import classNames from 'classnames';
import { useState, FC, MouseEvent } from 'react';
import style from './style.module.css';

interface AuthLayoutProps {
  MobileNav: FC<{ openNavCB: (e: MouseEvent) => void }>;
  Left: FC<{ closeNavCB: (e: MouseEvent) => void }>;
  Content: FC;
  Footer?: FC;
  isSearchPage?: boolean;
}

export function AuthLayout(props: AuthLayoutProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { MobileNav, Left, Content, Footer, isSearchPage = false } = props;
  return (
    <div
      className={style.authLayout}
      onClick={() => {
        setIsOpen(false);
      }}
      data-is-search-page={isSearchPage}
    >
      <div className={style.mobileNav}>
        <MobileNav
          openNavCB={(e) => {
            e.stopPropagation();
            setIsOpen(true);
          }}
        />
      </div>
      <div className={style.content}>
        <Content />
      </div>
      {Footer && (
        <div className={style.footer}>
          <Footer />
        </div>
      )}
      <div
        className={classNames(style.left, {
          [style.open]: isOpen,
        })}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Left
          closeNavCB={(e) => {
            e.stopPropagation();
            setIsOpen(false);
          }}
        />
      </div>
    </div>
  );
}
